(function() {
    'use strict';

    angular.module('adminApp')
        .component('aflAdminUsersFilterDropdown', {
            templateUrl: "/static/javascript/directives/afl-admin-users-filter-dropdown/afl-admin-users-filter-dropdown.html",
            controller: aflAdminUsersFilterDropdownController,
            bindings: {
                roles: '=',
                email: '=',
                rolesIncluded: '=',
                organizationRolesIncluded: '=',
                filterBy: '=',
                onApplyFilter: '&'
            }
        });

    aflAdminUsersFilterDropdownController.$inject = ['OrganizationRoleService'];

    function aflAdminUsersFilterDropdownController(OrganizationRoleService) {

        var $ctrl = this;

        $ctrl.title = 'Filter users by role';

        $ctrl.organizationRoles = OrganizationRoleService.getOrganizationRoles();

        $ctrl.includeRole = function (role, filterBy, check) {
            var index = $.inArray(role, $ctrl.rolesIncluded);

            (index === -1) ? $ctrl.rolesIncluded.push(role) : $ctrl.rolesIncluded.splice(index, 1);

            // temporary solution
            if(check) {
                $ctrl.rolesIncluded = [role];
            } else {
                $ctrl.rolesIncluded = [];
            }
            $ctrl.organizationRolesIncluded = [];
            $ctrl.email = '';
            $ctrl.setFilterBy(filterBy);
            $ctrl.onApplyFilter({
                searchBy: $ctrl.filterBy,
                email: $ctrl.email,
                rolesIncluded: $ctrl.rolesIncluded,
                organizationRolesIncluded: $ctrl.organizationRolesIncluded
            });
        };

        $ctrl.includeOrganizationRole = function includeOrganizationRole(role, filterBy, check) {

            if(check) {
                $ctrl.organizationRolesIncluded = [role];
            } else {
                $ctrl.organizationRolesIncluded = [];
            }
            $ctrl.rolesIncluded = [];
            $ctrl.email = '';
            $ctrl.setFilterBy(filterBy);
            $ctrl.onApplyFilter({
                searchBy: $ctrl.filterBy,
                email: $ctrl.email,
                rolesIncluded: $ctrl.rolesIncluded,
                organizationRolesIncluded: $ctrl.organizationRolesIncluded
            });
        };

        $ctrl.isRoleIncluded = function(role) {
            return !!$ctrl.rolesIncluded.find(function(element) {
                return element === role.id;
            })
        };

        $ctrl.isOrganizationRoleIncluded = function(role) {
            return !!$ctrl.organizationRolesIncluded.find(function(element) {
                return element === role.id;
            })
        };

        $ctrl.setFilterBy = function setFilterBy(filterBy) {
            $ctrl.filterBy = filterBy;
        }
    }
})();
